// App.js can't access the node_modules folder since it lives outside of src
// but scss files can. So we import the global styles from here.
@import '../Common/styles/styles';
@import '../../node_modules/smart-focus-ring/lib/styles.css';

// * fonts from https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin
/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto-v30-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

body {
    color: $gray;
    background-color: #FAFAFA;
    font-size: 14px;
}

a {
    color: $teal-dark;
    text-decoration: none;
    font-weight: $fw-bold;
    &:hover,
    &:focus {
        color: $teal;
        text-decoration: underline;
    }
}

h1 {
    font-size: 2.86em;
}

h2 {
    font-size: 2.5em;
}

#root {
    height: 100vh;
}
